import './index.css'

const WellnessCard = ({ isChecked, handleOnClick }) => {
    return (
        <div className='activity-card'>
            <div className='activity-card-body'>
                <h2 className='activity-card-body-title'>Wellness</h2>
                <p className='activity-card-body-content'>Renouveler l’énergie et l’attention de vos participants, avec une séance wellness (exercices de respiration, mouvements simples) animée par nos wellness officers. La séance de 15 minutes vous est offerte !</p>
                { isChecked ? <button className='activity-card-body-button-active' onClick={() => handleOnClick()}>Retirer</button> : <button className='activity-card-body-button' onClick={() => handleOnClick()}>Ajouter</button> }
            </div>
            <div className='activity-card-image' style={{backgroundImage: 'url("https://res.cloudinary.com/ddyhxyc8l/image/upload/w_1200,h_400,c_fill/v1647263910/booking/addons/wellness.jpg")'}}></div>
        </div>
    )
}

export default WellnessCard