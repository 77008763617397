import { useSelector, useDispatch } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { incrementStep } from '../../Features/formSlice'

import './index.css'


const StepCard = ({ stepNumber, nextStep, handleData, nextPath, previousPath }) => {

    const step = useSelector((state) => state.form.state)
    const isDisplay = useSelector((state) => state.form.isDisplay)

    const dispatch = useDispatch()
    const location = useLocation()

    const updateStep = () => {
        dispatch(incrementStep())
    }



    const titles = ["Quelle salle vous convient ?", "Quel est votre besoin en salle ?", "On mange un morceau ?", "Envie de réussir votre événement ?", "Et si on décompressait ?", "Confirmer votre  demande de réservation"]

    return (
        <div className="step-card">
            <h1 className="step-card-text">{titles[stepNumber]}</h1>
            {location.pathname.includes('/schedule') && (
                <>
                    {isDisplay && (
                        <>
                            <div className="step-card-buttons">
                                {/* { previousPath ? <Link className="step-card-button" to={previousPath}>Précédent</Link> : <></> } */}
                                <div className="step-card-button-desktop">
                                    {stepNumber < 5 && <Link className="step-card-button" to={nextPath} onClick={() => handleData()}><span>Continue</span></Link>}
                                </div>
                            </div>

                            <div className="step-card-button-mobile">
                                {stepNumber < 5 && <Link className="step-card-button" to={nextPath} onClick={() => handleData()}><span>Continue</span></Link>}
                            </div>
                        </>
                    )}
                </>
            )}




            {location.pathname !== '/schedule' && (
                <>
                    <div className="step-card-buttons">
                        {/* { previousPath ? <Link className="step-card-button" to={previousPath}>Précédent</Link> : <></> } */}
                        <div className="step-card-button-desktop">
                            {stepNumber < 5 && <Link className="step-card-button" to={nextPath} onClick={() => handleData()}><span>Continue</span></Link>}
                        </div>
                    </div>

                    <div className="step-card-button-mobile">
                        {stepNumber < 5 && <Link className="step-card-button" to={nextPath} onClick={() => handleData()}><span>Continue</span></Link>}
                    </div>
                </>
            )}
        </div>
    )
}

export default StepCard