import AfterworkCard from './AfterworkCard'
import './index.css'
import TeambuildingCard from './TeambuildingCard'

import config from '../../Config'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import StepCard from '../StepCard'
import { updateAfterwork, updateStep, updateTeambuilding } from '../../Features/formSlice'
import Header from '../Header'


const AfterworkTeambuilding = () => {

    const reduxAfterwork = useSelector((state) => state.form.afterwork)
    const reduxTeambuilding = useSelector((state) => state.form.teambuilding)
    const dispatch = useDispatch()

    const initialAfterwork = reduxAfterwork
    const initialTeambuidling = reduxTeambuilding

    const [afterwork, setAfterwork] = useState(initialAfterwork)
    const [teambuilding, setTeambuilding] = useState(initialTeambuidling)

    useEffect(() => {
        dispatch(updateStep(4))
    }, [])


    const handleData = () => {
        dispatch(updateAfterwork(afterwork))
        dispatch(updateTeambuilding(teambuilding))
    }

    const handleAfterworkOnClick = () => {
        setAfterwork(!afterwork)
    } 
    
    const handleTeambuildingOnClick = () => {
        setTeambuilding(!teambuilding)
    }

    return (
        <div>
            <Header/>
            <StepCard stepNumber={4} handleData={handleData} nextPath={'/reserve'} previousPath={'/wellness-facilitgator'}/>
            {config.afterwork && <AfterworkCard isChecked={afterwork} handleOnClick={handleAfterworkOnClick} />}
            {config.teambuilding && <TeambuildingCard isChecked={teambuilding} handleOnClick={handleTeambuildingOnClick} />}
        </div>
    )
}

export default AfterworkTeambuilding