import TextField from '@mui/material/TextField'
import {InputNumber} from 'antd'

import 'antd/dist/antd.css'
import { useEffect, useState } from 'react'
import './index.css'

const CateringSettingCard = ({ index, date, duration, handleLunchChange }) => {

    const [lunchAdded, setLunchAdded] = useState(false)

    useEffect(() => {
        if(duration === 'ampm') setLunchAdded(true)
    }, [])

    const test = () => {
        if (duration === 'ampm') {
            return <></>
        } else {
            if (lunchAdded) {
                return (
                    <div className='catering-setting-button-container'>
                        <button className='catering-setting-button' onClick={() => {handleOnClick(); handleLunchChange(index, 0)}}>
                            Retirer le déjeuner
                        </button>
                    </div>
                )
            }
            else {
                // handleLunchChange(index, 0)
                return (
                    <div className='catering-setting-button-container'>
                        <button className='catering-setting-button' onClick={() => handleOnClick()}>
                            Ajouter le déjeuner
                        </button>
                    </div>
                )
            }
        }
    }

    const handleOnClick = () => {
        setLunchAdded(!lunchAdded)
    }

    const inverseDate = (date, seperator) => {
        let splitedDate = date.split('-')
        return `${splitedDate[2]}${seperator}${splitedDate[1]}${seperator}${splitedDate[0]}`
    }


    const displayedDate = inverseDate(date, '-')

    return (
        <div className='catering-setting'>
            <div className='catering-setting-text'>
                <span>Restauration du: <span className="catering-date">{displayedDate}</span></span> {/* the date should be dynamic */}
            </div>
            <ul>
                <li>
                    <div className='catering-setting-inclus'>
                        <div></div>
                        <div className='catering-setting-inclus-text'>Inclus</div>
                    </div>
                    <div>
                        Petit-déjeuner
                    </div>
                </li>
                <li>
                    <div className='catering-setting-inclus'>
                        <div></div>
                        <div className='catering-setting-inclus-text'>Inclus</div>
                    </div>
                    <div>
                        Pauses gourmandes
                    </div>
                </li>
                <li>
                    <div className='catering-setting-inclus'>
                        <div></div>
                        <div className='catering-setting-inclus-text'><span>Inclus</span></div>
                    </div>
                    <div className='catering-setting-lanch'>
                        <div>Déjeuner</div>
                        {/* <InputNumber min={1} defaultValue={3} bordered={false} /> */}
                        <div className="catering-setting-lanch-input-wrapper">
                            { lunchAdded ? <TextField type='number' variant='standard' inputProps={{min: 1}} defaultValue={1} onChange={(e) => handleLunchChange(index, parseInt(e.target.value))}/> :  <TextField type='number' variant='standard' inputProps={{min: 1}} disabled={true} value={0} /> }
                        </div>
                        
                        <div>personnes</div>
                        
                    </div>
                </li>
            </ul>

            {
                test()
            }

            
            
        </div>
    )
}

export default CateringSettingCard