import FacilitationCard from './FacilitationCard'
import './index.css'
import WellnessCard from './WellnessCard'

import config from '../../Config'
import StepCard from '../StepCard'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { updateFacilitation, updateStep, updateWellness, updateBrainstorming } from '../../Features/formSlice'
import Header from '../Header'
import BrainstormingCard from './BrainstormingCard'

const WellnessFacilitation = () => {

    const reduxWellness = useSelector((state) => state.form.wellness)
    const reduxFacilitation = useSelector((state) => state.form.facilitation)
    const reduxBrainstorming = useSelector((state) => state.form.brainstorming)
    const dispatch = useDispatch()

    const initialWellness = reduxWellness
    const initialFacilitation = reduxFacilitation
    const initialBrainstorming = reduxBrainstorming

    const [wellness, setWellness] = useState(initialWellness)
    const [facilitation, setFacilitation] = useState(initialFacilitation)
    const [brainstorming, setBrainstorming] = useState(initialBrainstorming)

    useEffect(() => {
        dispatch(updateStep(3))
    }, [])

    const handleData = () => {
        dispatch(updateWellness(wellness))
        dispatch(updateFacilitation(facilitation))
    }

    const handleWellnessOnClick = () => {
        setWellness(!wellness)
    }

    const handleFacilitationOnClick = () => {
        setFacilitation(!facilitation)
    }

    const handleBrainstormingOnClick = () => {
        setBrainstorming(!brainstorming)
    }

    return (
        <div>
            <Header/>
            <StepCard stepNumber={3} handleData={handleData} nextPath={'/afterwork-teambuilding'} previousPath={'/catering'} />
            {config.wellness && <WellnessCard isChecked={wellness} handleOnClick={handleWellnessOnClick} />}
            {config.facilitation && <FacilitationCard isChecked={facilitation} handleOnClick={handleFacilitationOnClick} />}
            <BrainstormingCard isChecked={brainstorming} handleOnClick={handleBrainstormingOnClick} />
        </div>
    )
}

export default WellnessFacilitation