import './index.css'

const BrainstormingCard = ({ isChecked, handleOnClick }) => {
    return (
        <div className='activity-card'>
            <div className='activity-card-body'>
                <h2 className='activity-card-body-title'>Brainstorming</h2>
                <p className='activity-card-body-content'>Intégrez la technologie dans vos meetings pour plus d'engagement et d'interactivité : Klaxoon, Mulitmeter....</p>
                { isChecked ? <button className='activity-card-body-button-active' onClick={() => handleOnClick()}>Retirer</button> : <button className='activity-card-body-button' onClick={() => handleOnClick()}>Ajouter</button> }
            </div>
            <div className='activity-card-image' style={{backgroundImage: 'url("https://res.cloudinary.com/ddyhxyc8l/image/upload/w_1200,h_400,c_fill/v1647263910/booking/addons/facilitator.jpg")', aspectRatio: '28/2'}}></div>
        </div>
    )
}

export default BrainstormingCard