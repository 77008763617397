import { NativeSelect, TextField } from '@mui/material'
// import { InputNumber } from 'antd'

const RoomSettingSalleCard = ({ parentIndex, room, index, handleRemoveSalle, handleUpdateSalle, handleRemoveSalleCard, id }) => {
    // console.log('salle', index);
    return (
        <>
            <div className='room-setting-settings'>
                <div className='room-setting-settings-title-wrapper'>
                    <div className='room-setting-settings-text'>
                        { index ? 'Salle annexe' : 'Salle principale' }
                    </div>
                    {
                        index ? (<div className='room-setting-remove' onClick={() => {handleRemoveSalle(parentIndex, index); handleRemoveSalleCard()}}>Retirer la salle</div>) : (<></>)
                    }
                </div>

                <label htmlFor="duration">
                    <div className='room-setting-settings-inputs-text'>Durée <span className='required'>*</span></div>
                    <NativeSelect defaultValue={room.duration} inputProps={{name:'duration', id:'duration'}} onChange={(e) => handleUpdateSalle(parentIndex, index, {duration: e.target.value})} >
                        <option value={"am"}>Matin</option>
                        <option value={"pm"}>Après-midi</option>
                        <option value={"ampm"} selected>Toute la journée</option>
                    </NativeSelect>    
                </label>

                <label htmlFor="participants">
                    <div className='room-setting-settings-inputs-text'>Participants <span className='required'>*</span></div>
                    {/* <input type="number" name="participants" id="participants" min='1'required/> */}
                    {/* <InputNumber min={1} defaultValue={4} bordered={false} /> */}
                    <TextField 
                        type='number' 
                        variant='standard' 
                        defaultValue={room.participants} 
                        inputProps={{
                            min: 1
                        }}
                        onInput={(e) => handleUpdateSalle(parentIndex, index, {participants: parseInt(e.target.value)})}
                        />
                </label>

                <label htmlFor="setup">
                    <div className='room-setting-settings-inputs-text'>Configuration <span className='required'>*</span></div>
                    <NativeSelect defaultValue={room.setup} inputProps={{name:"setup", id:"setup"}} onChange={(e) => handleUpdateSalle(parentIndex, index, {setup: e.target.value})} >
                        <option value="board" selected>Board</option>
                        <option value="u">Format U</option>
                        <option value="casual">Casual</option>  
                        <option value="theater">Théâtre</option>
                        <option value="cabarets">Cabarets/Îlots</option>
                        <option value="auditorium">Auditorium</option>
                        <option value="ecole">École</option>
                    </NativeSelect>
                </label>
            </div>

            <hr/>
        </>
    )
}

export default RoomSettingSalleCard