import './index.css'
import { ReactComponent as CheckCircle } from '../../assets/images/CheckCircle.svg'
import Header from '../Header'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { updateStep } from '../../Features/formSlice'


const LastStep = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(updateStep(6))
    }, [])


    return (
        <div>
            <Header />
            <div className='laststep-container'>
                <div className='laststep-check-circle-container'>
                    <CheckCircle className='laststep-check-circle' />
                </div>
                <div className='laststep-text-container'>
                    <h2 className='laststep-text'>Votre demande de réservation a été bien prise en compte,</h2>
                    <h2 className='laststep-text'>Un agent wemeet prendra attache avec vous dans les plus brefs délais</h2>
                </div>
            </div>
        </div>
    )
}

export default LastStep