import './App.css'
import Footer from './Components/Layout/Footer';
import Navbar from './Components/Layout/Navbar';
import Header from './Components/Header';
import { doesAfterworkTeambuildingExists, doesWellnessFacilitationExists } from './Helpers';
import FormRoutes from './Routes';

// const [step, setStep] = useState()

// const stepsComponents = [
//   Place,
//   Room,
//   Catering,
//   WellnessFacilitation,
//   AfterworkTeambuilding
// ]


function App() {
  return (
    <div className="App">
      {/* <Navbar/>
      <div className='header-upper'>
        <Header/>
        <Place />
        <Room />
        <Catering />
        { doesWellnessFacilitationExists() && <WellnessFacilitation /> }
        { doesAfterworkTeambuildingExists() && <AfterworkTeambuilding /> }
      </div>
      <Footer/> */}

      <Navbar/>
      <div className='header-upper'>
        {/* <Header/> */}
        <FormRoutes />
      </div>
      {/* <Footer/> */}
      {/* <FormRoutes /> */}
    </div>
  );
}

export default App;
