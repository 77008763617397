import { useEffect, useState } from 'react'
import './index.css'
import PlaceDateSetter from './PlaceDateSetterCard'
import RoomAddDay from './RoomAddDay'
import RoomsEquipmentsCard from './RoomsEquipmentsCard'
import RoomSettingCard from './RoomSettingCard'

import { useSelector, useDispatch } from 'react-redux'
import { updateDays, updateStep } from '../../Features/formSlice'
import StepCard from '../StepCard'
import Header from '../Header'
import RoomImageCard from './RoomImageCard'

import { ReactComponent as BoardSVG } from './SVG/board.svg'
import { ReactComponent as USVG } from './SVG/u.svg'
import { ReactComponent as CasualSVG } from './SVG/casual.svg'
import { ReactComponent as TheaterSVG } from './SVG/theater.svg'
import { ReactComponent as CabaretsSVG } from './SVG/cabarets.svg'
import { ReactComponent as AuditoriumSVG } from './SVG/auditorium.svg'

import { Auditorium, Board, Cabaret, Casual, FormatU, Theatre } from '../../assets/images/Setups'


const Room = () => {

    let reduxDays = useSelector((state) => state.form.days)
    const dispatch = useDispatch()

    const initialStartDate = reduxDays[0] ? new Date(reduxDays[0].date) : ''

    const [startDate, setStartDate] = useState(initialStartDate)
    const [lastDate, setLastDate] = useState('')
    const [days, setDays] = useState(reduxDays)

    // console.log('redux', reduxDays);
    // console.log('local hehe', days);
    
    const [numberOfRoomSettingCard, setNumberOfRoomSettingCard] = useState(reduxDays.length)

    const dateFormated = (givenDate, days = 0, separator = '/', isForDate = false) => {
        // console.log('inside the dateFormated', givenDate);
        const date = new Date(givenDate.getTime() + days*24*60*60*1000)
    

        const day  = `${date.getDate()}`.length < 2 ? `0${date.getDate()}` : `${date.getDate()}`
        const month = `${date.getMonth() + 1}`.length < 2 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`
        const year = `${date.getFullYear()}`
        
        if (isForDate) return `${year}${separator}${month}${separator}${day}`

        return `${day}${separator}${month}${separator}${year}`
    }

    const handleSetStartDate = (e) => {

        // const d =
        // const date = new Date(d)
        const date = e
        setStartDate(date)
        if (Number.isNaN(date.getTime())) {
            setDays([])
            setNumberOfRoomSettingCard(0)
        } else if (!numberOfRoomSettingCard) {
            setDays([{
                date: dateFormated(e, 0, '-', true),
                rooms: [{
                    duration: 'ampm',
                    participants: 1,
                    setup: 'board'
                }]
            }])
            setNumberOfRoomSettingCard(1)
        } else {
            let tempDays = [...days]
            for (let i = 0; i < tempDays.length; i++) {
                // console.log('else', tempDays[i].date);
                tempDays[i] = {...tempDays[i], date: dateFormated(date, i, '-', true)}
            }
            setDays(tempDays)
        }
        // TODO : else: change the dates in days state 
    }

    const handleAddDay = () => {
        setDays([...days, {
            date: dateFormated(startDate, days.length, '-', true),
            rooms: [{
                duration: 'ampm',
                participants: 1,
                setup: 'board'
            }]
        }])

        // console.log([...days, {
        //     date: dateFormated(startDate, days.length, '-', true),
        //     rooms: []
        // }])

        setNumberOfRoomSettingCard(numberOfRoomSettingCard + 1)
    }

    const handleRemoveSettingCard = () => {
        setDays(days.filter((_, i) => i !== days.length - 1))
        // console.log(days.filter((_, i) => i !== days.length - 1))
        setNumberOfRoomSettingCard(numberOfRoomSettingCard - 1)
    }

    const handleAddSalle = (dayIndex) => {
        let tempDays = [...days]
        // console.log('handleAddSalle', tempDays[dayIndex].rooms);

        let tempRooms = [...tempDays[dayIndex].rooms]

        tempRooms.push({
            duration: 'ampm',
            participants: 1,
            setup: 'board'
        })

        tempDays[dayIndex] = {...tempDays[dayIndex], rooms: tempRooms}
        setDays(tempDays)
    }

    const handleRemoveSalle = (dayIndex, roomIndex) => {
        let tempDays = [...days]
        let tempRooms = [...tempDays[dayIndex].rooms]
        tempRooms.splice(roomIndex, 1)
        tempDays[dayIndex] = {...tempDays[dayIndex], rooms: tempRooms}
        setDays(tempDays)
    }

    const handleUpdateSalle = (dayIndex, roomIndex, room) => {
        let tempDays = [...days]
        let tempRooms = [...tempDays[dayIndex].rooms]
        let tempRoom = {...tempDays[dayIndex].rooms[roomIndex]}
        // tempDays[dayIndex] = [...tempRooms, {...tempRoom, ...room}]
        tempRooms[roomIndex] = {...tempRoom, ...room}
        tempDays[dayIndex] = {...tempDays[dayIndex], rooms: tempRooms}
        // tempDays[dayIndex].rooms[roomIndex] = {...tempRoom, ...room}
        setDays(tempDays)
    }

    const nextStep = () => {
        // if (step < maxStep) {
        //     setStep(step + 1)
        //     console.log(step + 1)
        // }
    }

    const handleData = () => {
        dispatch(updateDays(days))
    }

    useEffect(() => {
        dispatch(updateStep(1))
    }, [])


    // console.log(startDate.getTime());
    // console.log(days);
    return (
        <div>
            <Header/>
            <StepCard stepNumber={1} handleData={handleData} nextPath={'/catering'} previousPath={'/'} />
            <PlaceDateSetter handleSetStartDate={handleSetStartDate} value={reduxDays[0]?.date} minDate={dateFormated(new Date(), 1, '-', true)} />

            {
                days.map((day, id) => <RoomSettingCard 
                                            id={`room-set-${id}`}
                                            key={id}
                                            date={day.date}
                                            rooms={day.rooms}
                                            index={id}
                                            lastIndex={numberOfRoomSettingCard - 1} 
                                            handleRemoveSettingCard={handleRemoveSettingCard} 
                                            handleAddSalle={handleAddSalle}
                                            handleRemoveSalle={handleRemoveSalle}
                                            handleUpdateSalle={handleUpdateSalle}
                                                />)
            }

            { numberOfRoomSettingCard ? (<RoomAddDay handleAddDay={handleAddDay} lastDay={days.length - 1}/>) : (<></>)}

            <RoomsEquipmentsCard />

            <RoomImageCard image={Board} title={'Board'} svg={<BoardSVG />} />
            <RoomImageCard image={FormatU} title={'Format U'} svg={<USVG />} />
            <RoomImageCard image={Casual} title={'Casual'} svg={<CasualSVG />} />
            <RoomImageCard image={Theatre} title={'Théâtre'} svg={<TheaterSVG />} />
            <RoomImageCard image={Cabaret} title={'Cabarets/Îlots'} svg={<CabaretsSVG />} />
            <RoomImageCard image={Auditorium} title={'Auditorium'} svg={<AuditoriumSVG />} />
        </div>
    )
}

export default Room