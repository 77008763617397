import './index.css'

const AfterworkCard = ({ isChecked, handleOnClick }) => {
    return (
        <div className='activity-card'>
            <div className='activity-card-body'>
                <h2 className='activity-card-body-title'>Afterwork & Soirée</h2>
                <p className='activity-card-body-content'>Terminer votre journée de travail chez Wemeet sur une note festive, avec nos offres Afterwork, Cocktail ou Soirée !</p>
                { isChecked ? <button className='activity-card-body-button-active' onClick={() => handleOnClick()}>Retirer</button> : <button className='activity-card-body-button' onClick={() => handleOnClick()}>Ajouter</button> }
            </div>
            <div className='activity-card-image' style={{backgroundImage: 'url("https://res.cloudinary.com/ddyhxyc8l/image/upload/w_1200,h_400,c_fill/v1647268054/booking/addons/afterwork.jpg")', 'aspect-ratio': '14.5 / 1'}}></div>
        </div>
    )
}

export default AfterworkCard