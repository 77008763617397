import { useEffect } from 'react'
import './index.css'

const PlaceSetterCard = ({ handleOnClick, selectedPlace, salles }) => {

    useEffect(() => {
        const buttons = document.querySelectorAll('.place-setter-button')
        buttons.forEach(button => {
            if (button.textContent === selectedPlace) button.classList.add("place-setter-button-selected")
            else button.classList.remove("place-setter-button-selected")
        })
    }, [selectedPlace])
    
    const changeClassOnClick = (clickedButton) => {
        const buttons = document.querySelectorAll('.place-setter-button')
        buttons.forEach(button => {
            button.classList.remove("place-setter-button-selected")
        })
        clickedButton.target.classList.add("place-setter-button-selected")
        // console.log(clickedButton);
    }

    return (
        <div className="place-setter">
            <button className="place-setter-button" onClick={(e) => {handleOnClick('Paris');changeClassOnClick(e)}}>Paris</button>
            <button className="place-setter-button" onClick={(e) => {handleOnClick('Havana');changeClassOnClick(e)}}>Havana</button>
            <button className="place-setter-button" onClick={(e) => {handleOnClick('Bali');changeClassOnClick(e)}}>Bali</button>
            <button className="place-setter-button" onClick={(e) => {handleOnClick('Florence');changeClassOnClick(e)}}>Florence</button>
            <button className="place-setter-button" onClick={(e) => {handleOnClick('New York');changeClassOnClick(e)}}>New York</button>
            <button className="place-setter-button" onClick={(e) => {handleOnClick('Londres');changeClassOnClick(e)}}>Londres</button>



            {/* {
                salles.map((salle) => <button className="place-setter-button" onClick={(e) => {handleOnClick({salle});changeClassOnClick(e)}}>{salle}</button>)
            } */}

        </div>
    )
}

export default PlaceSetterCard