import { NativeSelect, TextField } from '@mui/material'
import { InputNumber } from 'antd'
import { useEffect, useState } from 'react'
import './index.css'
import RoomSettingSalleCard from './RoomSettingSalleCard'

const RoomSettingCard = ({ date, rooms, index, lastIndex, handleRemoveSettingCard, handleAddSalle, handleRemoveSalle, handleUpdateSalle, id }) => {

    const [numberOfRoomSettingSalleCard, setNumberOfRoomSettingSalleCard] = useState(1)

    const handleAddSalleCard = () => {
        setNumberOfRoomSettingSalleCard(numberOfRoomSettingSalleCard + 1)
    }

    const handleRemoveSalleCard = () => {
        setNumberOfRoomSettingSalleCard(numberOfRoomSettingSalleCard - 1)
    }

    const inverseDate = (date, seperator) => {
        let splitedDate = date.split('-')
        return `${splitedDate[2]}${seperator}${splitedDate[1]}${seperator}${splitedDate[0]}`
    }

    const inversedDate = inverseDate(date, '-')

    return (
        <div className='room-setting' id={id}>
            <div className='room-setting-text-wrapper'>
                <div className='room-setting-text'>Journée du {inversedDate}</div> {/* this should be dynamic from the props it will get the date */}
                { index && index === lastIndex ? (<div className='room-setting-remove' onClick={() => handleRemoveSettingCard()}>Retirer la journée</div>) : (<></>) }
                
            </div>
            
            {
                rooms.map((room, id) => <RoomSettingSalleCard 
                                            index={id}
                                            room={room}
                                            parentIndex={index}
                                            handleRemoveSalle={handleRemoveSalle}
                                            handleUpdateSalle={handleUpdateSalle}
                                            handleRemoveSalleCard={handleRemoveSalleCard}
                                                />)
            }

            <button className='room-setting-add-button' onClick={() => {handleAddSalle(index); handleAddSalleCard()}}>
                Ajouter une salle
            </button>
        </div>
    )
}

export default RoomSettingCard