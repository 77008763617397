import './index.css'

const MenuCard = ({ title, content, image }) => {
    return (
        <div className='menu-card-container'>
            <div className='menu-card-body'>
                <div className='menu-card-body-title'>{ title }</div>
                <p className='menu-card-body-content'>{ content }</p>
            </div>
            <div><img src={image} alt="dej" className='menu-card-image' /></div>
        </div>
    )
}

export default MenuCard