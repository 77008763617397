import './index.css'

const RoomAddDay = ({ handleAddDay, lastDay }) => {

    const directToTheLastDay = (lastDay) => {
        window.location.href = `#room-set-${lastDay}`
    }


    return (
        <div className='room-add-day'>
            <div className='room-add-day-text'>
                Je souhaite ajouter une journée à mon événement
            </div>
            <button className='room-add-day-button' onClick={() => {handleAddDay();directToTheLastDay(lastDay)}}>
                Ajouter
            </button>
        </div>
    )
}

export default RoomAddDay