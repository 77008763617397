import Logo from '../../../assets/images/Logo.svg'

const Navbar = () => {
    return (
        <>
            <nav className='navbar'>
                <a href="/" className='logo'>
                    <img src={Logo} alt="" width='150' id="logo"/>
                </a>
            </nav>
            <hr style={{height: '3px', backgroundColor: '#FAA278', border: 'none'}}/>
        </>
    )
}

export default Navbar