// import { DatePicker } from 'antd'
// import DatePicker from '@mui/x-date-pickers-pro/DatePicker';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// or
// import DatePicker from '@mui/x-date-pickers/DatePicker';
// or
// import { DatePicker } from '@mui/x-date-pickers-pro';
// or
// import { DatePicker } from '@mui/x-date-pickers';

// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import TextField from '@mui/material/TextField'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { fr } from "date-fns/locale";


import './index.css'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setIsDisplay } from '../../../Features/formSlice';

const PlaceDateSetter = ({ handleSetStartDate, value, minDate }) => {
    let date = new Date(new Date().getTime() + 24*60*60*1000)
    const dispatch = useDispatch()
    // date = `${date.getFullYear()}-${`${date.getMonth() + 1}`.length < 2 ? '0' + (date.getMonth() + 1): date.getMonth() + 1}-${`${date.getDate()}`.length < 2 ? '0' + date.getDate(): date.getDate()}`

    const [val, setVal] = useState(value || null)
    
    return (
        <div className="place-date">
            <label htmlFor="date">J’ai besoin d’une salle à partir du &nbsp;</label>
            {/* <input type="date" name="" id=""/> */}
            {/* <TextField
                id="date"
                type="date"
                // defaultValue={date}
                sx={{ width: 220 }}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    min: minDate
                }}
                variant='standard'
                defaultValue={value}
                onChange={(e) => handleSetStartDate(e)}
            /> */}

            {/* <DatePicker
                views={['day', 'month', 'year']}
                label="Invert the order of views"
                value={value}
                onChange={(newValue) => {
                    setValue(newValue);
                }}
                renderInput={(params) => <TextField {...params} helperText={null} />}
            /> */}

            <LocalizationProvider locale={fr} dateAdapter={AdapterDateFns}>
                <DatePicker 
            
                    label="Choisir une date"
                    disablePast={true}
                    value={val}
                    inputFormat="E dd MMM yyyy"
                    onChange={(e) => {
                        handleSetStartDate(e);setVal(e);window.location.href="#room-set-0"
                        dispatch(setIsDisplay(true))
                    }}
                    renderInput={(params) => <TextField {...params} inputProps={{...params.inputProps, placeholder: "Choisir une date"}} />}
                    showToolbar={true}
                />
            </LocalizationProvider>
        </div>
    )
}

export default PlaceDateSetter