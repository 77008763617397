import config from '../Config'



export const doesWellnessExists = () => {
    return config.wellness
}

export const doesFacilitationExists = () => {
    return config.facilitation
}

export const doesAfterworkExists = () => {
    return config.afterwork
}

export const doesTeambuildingExists = () => {
    return config.teambuilding
}

export const doesWellnessFacilitationExists = () => {
    return doesWellnessExists() || doesFacilitationExists()
}

export const doesAfterworkTeambuildingExists = () => {
    return doesAfterworkExists() || doesTeambuildingExists()
}

export const doWellnessAndFacilitationExist = () => {
    return doesWellnessExists() && doesFacilitationExists()
}

export const doAfterworkAndTeambuildingExist = () => {
    return doesAfterworkExists() && doesTeambuildingExists()
}

export const getWellnessFacilitationStepTitle = () => {
    if (doWellnessAndFacilitationExist()) {
        return 'Wellness / Facilitation'
    } else if (doesWellnessExists()) {
        return 'Wellness'
    } else if (doesFacilitationExists()) {
        return 'Facilitation'
    }

    return ''
}

export const getAfterworkTeambuildingStepTitle = () => {
    if (doAfterworkAndTeambuildingExist()) {
        return 'Afterwork / Teambuilding'
    } else if (doesAfterworkExists()) {
        return 'Afterwork'
    } else if (doesTeambuildingExists()) {
        return 'Teambuilding'
    }
    
    return ''
}

export const countSteps = () => {
    // returns a zero based steps
    if (doesWellnessFacilitationExists() && doesAfterworkTeambuildingExists()) {
        return 5
    }
    if (doesWellnessFacilitationExists() || doesAfterworkTeambuildingExists()) {
        return 4
    }
    return 3

}