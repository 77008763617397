import './index.css'

const FacilitationCard = ({ isChecked, handleOnClick }) => {
    return (
        <div className='activity-card'>
            <div className='activity-card-body'>
                <h2 className='activity-card-body-title'>Facilitation</h2>
                <p className='activity-card-body-content'>Besoin d’un intervenant pour vous aider à préparer et/ou animer votre événement ? Notre réseau de facilitateurs, animateurs ou experts indépendants, est recommandé par notre communauté de clients.</p>
                { isChecked ? <button className='activity-card-body-button-active' onClick={() => handleOnClick()}>Retirer</button> : <button className='activity-card-body-button' onClick={() => handleOnClick()}>Ajouter</button> }
            </div>
            <div className='activity-card-image' style={{backgroundImage: 'url("https://res.cloudinary.com/ddyhxyc8l/image/upload/w_1200,h_400,c_fill/v1647263910/booking/addons/facilitator.jpg")'}}></div>
        </div>
    )
}

export default FacilitationCard