import { doesWellnessFacilitationExists, doesAfterworkTeambuildingExists, getWellnessFacilitationStepTitle, getAfterworkTeambuildingStepTitle, countSteps } from '../../Helpers'
import './index.css'
import StepCard from "../StepCard"
import { Steps } from 'antd'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useRef } from 'react'
const { Step } = Steps

const StepBar = () => {

    const step = useSelector((state) => state.form.step)

    const currentStepRef = useRef()

    const centerCurrentStep = () => {
          setTimeout(() => {
            currentStepRef.current.scrollIntoView({
              behavior: "instant",
              inline: "center",
            });
          }, 1000);
    }

    const myFunction = () => {
        window.location.href = `#${step}`
        setTimeout(() => {
            window.location.href = '#logo'
          }, 0);
    }

    // const step = 3
    
    let navigate = useNavigate()

    const maxStep = countSteps()

    const wellnessFacilitationStepTitle = getWellnessFacilitationStepTitle()
    const afterworkTeambuildingStepTitle = getAfterworkTeambuildingStepTitle()

    useEffect(() => {
        // centerCurrentStep()
        myFunction()
    }, [step])
    const nextStep = () => {
        // if (step < maxStep) {
        //     setStep(step + 1)
        //     console.log(step + 1)
        // }
    }   

    const stepTitles = ['Salle', 'Vos besoins', 'Restauration', wellnessFacilitationStepTitle, afterworkTeambuildingStepTitle, 'Inscription', 'Merci']
    const stepLinks = ['/', '/schedule', '/catering', '/wellness-facilitgator', '/afterwork-teambuilding', '/reserve', '/merci']

    return (
        <>
            <div className="header-text">
                <p>Vérifiez la disponibilité d’un lieu<br/> & <span className="secondary-color">obtenez un devis en quelques clics</span></p>
            </div>
            <div className="header">
                <div className='header-steps'>
                    {/* TODO : step bar */}
                    <Steps progressDot current={step} responsive={false}>
                        
                        {
                            stepTitles.map((stepTitle, stepNumber) => {
                                if (stepTitle) {
                                    // console.log('step:', step);
                                    // if step === stepNumber 
                                    if ( step === stepNumber ) return <Step title={stepTitle} ref={currentStepRef} id={stepNumber}/>
                                    return step > stepNumber ? <Step title={stepTitle} onClick={() => {navigate(stepLinks[stepNumber])}} id={stepNumber} /> : <Step title={stepTitle}  id={stepNumber} />
                                }
                                
                            })
                        }
                        {/* <Step title='Salle' status='process'/>
                        <Step title='Vos besoins' />
                        <Step title='Restauration' />
                        { doesWellnessFacilitationExists() && <Step title={wellnessFacilitationStepTitle} /> }
                        { doesAfterworkTeambuildingExists() && <Step title={afterworkTeambuildingStepTitle} /> }
                        <Step title='Inscription / Connexion' /> */}
                    </Steps>
                </div>
                {/* <StepCard stepNumber={step} nextStep={nextStep}/> */}
            </div>
        </>
    )
}

export default StepBar