import './index.css'
import { Carousel, Modal } from 'antd';
import { useEffect, useState } from 'react'
import { Peninsula1, Peninsula2, Peninsula3, Peninsula4, Peninsula5, Peninsula6, Peninsula7 } from '../../../assets/images/Peninsula'
import { ReactComponent as Zoom } from '../../../assets/images/zoom.svg'


const PlaceImageCard = ({ name, selectedPlace, handleOnClick, desktopImage, mobileImage }) => {

    const [isSelected, setIsSelected] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [placeImage, setPlaceImage] = useState(desktopImage)

    const PeninsulaImages = [Peninsula1, Peninsula2, Peninsula3, Peninsula4, Peninsula5, Peninsula6, Peninsula7]
    const CottageImages = []
    const PeacockImages = []

    const showModal = () => {
        setIsModalVisible(true)
    }

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const contentStyle = {
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
    }

    const smallDevices = window.matchMedia("only screen and (max-width: 767px)");

    const s_imageSizeController = (x) => {
        if (x.matches) {
            setPlaceImage(mobileImage)
        } else {
            setPlaceImage(desktopImage)
        }
    }


    useEffect(()=>{
        s_imageSizeController(smallDevices)
        smallDevices.addListener(s_imageSizeController)
        setIsSelected(name === selectedPlace)
    },[selectedPlace])

    console.log(placeImage, 'placeImage')
    
    return (
        <div>
            <label style={{backgroundImage: `url("${placeImage}")`}} className="test">
                <div className="place-image-card-button">
                    <div>
                        {
                            isSelected ? (<input type="radio" name="place" onClick={() => handleOnClick(name)} checked/>) : (<input type="radio" name="place" onClick={() => handleOnClick(name)}/>)
                        }
                        &nbsp;{name}
                    </div>
                    <div onClick={showModal} style={{cursor: 'pointer'}}>
                        {/* <img src={ZoomIcon} alt="zoom" className='zoom-icon' /> */}
                        <Zoom className='zoom-icon' />
                    </div>
                </div>
            </label>
            {/* <label className="place-image-card">
                <div style={{backgroundImage: 'url("https://res.cloudinary.com/ddyhxyc8l/image/upload/w_1200,h_400,c_fill,g_auto/v1647275368/booking/mail/cover.jpg")'}} className="test">
                    <div className="place-image-card-button">
                        <div>
                            {
                                isSelected ? (<input type="radio" name="place" onClick={() => handleOnClick(name)} checked/>) : (<input type="radio" name="place" onClick={() => handleOnClick(name)}/>)
                            }
                            &nbsp;{name}
                        </div>
                        <div onClick={showModal} style={{cursor: 'pointer'}}> */}
                            {/* <img src={ZoomIcon} alt="zoom" className='zoom-icon' /> */}
                            {/* <Zoom className='zoom-icon' />
                        </div>
                    </div>
                </div> */}
                {/* <div className='place-image-card-image-container'>
                    <img className="place-image-card-image" src="https://res.cloudinary.com/ddyhxyc8l/image/upload/w_1200,h_400,c_fill,g_auto/v1647275368/booking/mail/cover.jpg" />
                </div> */}
            {/* </label> */}
            <Modal title={null} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null} centered={true} width={1040}>
            <Carousel>
                 {
                    name === "Paris" && PeninsulaImages.map(image => <div><img src={image} alt="" className='place-image-card-carousel' /></div>)
                }

                
                {
                    name === "Havana" && PeninsulaImages.map(image => <div><img src={image} alt="" className='place-image-card-carousel' /></div>)
                }

               
                {
                    name === "Zen" && PeninsulaImages.map(image => <div><img src={image} alt="" className='place-image-card-carousel' /></div>)
                }

                {
                    name === "Bleu" && PeninsulaImages.map(image => <div><img src={image} alt="" className='place-image-card-carousel' /></div>)
                }
                {
                    name === "New York" && PeninsulaImages.map(image => <div><img src={image} alt="" className='place-image-card-carousel' /></div>)
                }
                {
                    name === "Londres" && PeninsulaImages.map(image => <div><img src={image} alt="" className='place-image-card-carousel' /></div>)
                }
            </Carousel>
            </Modal>
        </div>
    )
}

export default PlaceImageCard