import { createSlice } from '@reduxjs/toolkit'

export const formSlice = createSlice({
    name: 'form',
    initialState: {
        step: 0,
        name: '',
        email: '',
        phone: '',
        place: '',
        days: [],
        lunch: [],
        wellness: false,
        facilitation: false,
        afterwork: false,
        teambuilding: false,
        brainstorming: false,
        isDisplay: false,
    },
    reducers: {
        incrementStep: (state) => {
            state.step++
        },
        decrementStep: (state) => {
            state.step--
        },
        updateStep: (state, action) => {
            state.step = action.payload
        },
        updateName: (state, action) => {
            state.name = action.payload
        },
        updateEmail: (state, action) => {
            state.email = action.payload
        },
        updatePhone: (state, action) => {
            state.phone = action.payload
        },
        updatePlace: (state, action) => {
            state.place = action.payload
        },
        updateDays: (state, action) => {
            state.days = action.payload
        },
        updateLunch: (state, action) => {
            state.lunch = action.payload
        },
        updateWellness: (state, action) => {
            state.wellness = action.payload
        },
        updateFacilitation: (state, action) => {
            state.facilitation = action.payload
        },
        updateAfterwork: (state, action) => {
            state.afterwork = action.payload
        },
        updateTeambuilding: (state, action) => {
            state.teambuilding = action.payload
        },
        updateBrainstorming: (state, action) => {
            state.brainstorming = action.payload
        },
        setIsDisplay: (state, action) => {
            state.isDisplay = action.payload
        },
    }
})

export const { updateBrainstorming, incrementStep, decrementStep, updateStep, updateAfterwork, updateDays, updateEmail, updateFacilitation, updateLunch, updateName, updatePhone, updatePlace, updateTeambuilding, updateWellness,setIsDisplay } = formSlice.actions

export default formSlice.reducer