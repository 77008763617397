import './index.css'

const TeambuildingCard = ({ isChecked, handleOnClick }) => {
    return (
        <div className='activity-card'>
            <div className='activity-card-body'>
                <h2 className='activity-card-body-title'>Teambuilding</h2>
                <p className='activity-card-body-content'>Renforcer la cohésion de vos équipes grâce à nos activités team building. Nous pouvons l’organiser pour vous, que ce soit chez Wemeet ou dans nos lieux partenaires !</p>
                { isChecked ? <button className='activity-card-body-button-active' onClick={() => handleOnClick()}>Retirer</button> : <button className='activity-card-body-button' onClick={() => handleOnClick()}>Ajouter</button> }
            </div>
            <div className='activity-card-image' style={{backgroundImage: 'url("https://res.cloudinary.com/ddyhxyc8l/image/upload/w_1200,h_400,c_fill/v1647267937/booking/addons/teambuilding.jpg")', 'aspect-ratio': '21 / 1'}}></div>
        </div>
    )
}

export default TeambuildingCard