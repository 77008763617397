import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateLunch, updateStep } from '../../Features/formSlice'
import StepCard from '../StepCard'
import CateringSettingCard from './CateringSettingCard'
import Header from '../Header'
import './index.css'
import MenuCard from './MenuCard'

const Catering = () => {

    const reduxDays = useSelector((state) => state.form.days)
    const reduxLunch = useSelector((state) => state.form.lunch)
    const dispatch = useDispatch()

    const initialLunch = reduxLunch.length === 0 ? Array(reduxDays.length).fill(0) : reduxLunch

    const [lunch, setLunch] = useState(initialLunch)

    useEffect(() => {
        dispatch(updateStep(2))
    }, [])

    const handleLunchChange = (index, value) => {
        let tempLunch = [...lunch]   

        tempLunch[index] = value

        // console.log(tempLunch);
        setLunch(tempLunch)
    }

    const handleData = () => {
        dispatch(updateLunch(lunch))
    }

    return (
        <div>
            <Header/>
            <StepCard stepNumber={2} handleData={handleData} nextPath={'/wellness-facilitgator'} previousPath={'/schedule'} />
            {
                reduxDays.map((day, id) => <CateringSettingCard key={id} index={id} date={day.date} duration={day.rooms[0].duration} handleLunchChange={handleLunchChange} />)
            }

            <MenuCard title={'Petit-déjeuner'} content={'Bien commencer la journée avec notre large buffet de boissons, fruits et viennoiseries, et laitages.'} image={'https://res.cloudinary.com/ddyhxyc8l/image/upload/w_1200,h_400,c_fill/v1646239459/booking/food/breakfast.jpg'} />
            <MenuCard title={'Déjeuner'} content={'Restaurez-vous en équipe en proftant de nos buffets assis. Large choix de viandes, poissons ou plats végétariens, légumes et fruits de saison, fromages et desserts.'} image={'https://res.cloudinary.com/ddyhxyc8l/image/upload/w_1200,h_400,c_fill/v1646239459/booking/food/lunch.jpg'} />
            <MenuCard title={'Pauses gourmandes'} content={'Stimuler votre productivité et votre gourmandise avec nos fruits secs, bonbons et jus healthy en libre service dans nos espaces de pause.'} image={'https://res.cloudinary.com/ddyhxyc8l/image/upload/w_1200,h_400,c_fill/v1646239246/booking/food/gourmet-breaks.jpg'} />
        </div>
    )
}

export default Catering