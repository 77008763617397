import './index.css'
import PlaceSetterCard from './PlaceSetterCard'
import PlaceImageCard from './PlaceImageCard'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updatePlace, updateStep } from '../../Features/formSlice'
import StepCard from '../StepCard'
import Header from '../Header'
import { Cottage, CottageMobile, TheClub, TheClubMobile, Zen, ZenMobile, Peps, PepsMobile,SalleNewyork, SalleLondre } from '../../assets/images/PlacePictures'



const Place = () => {

    const reduxPlace = useSelector((state) => state.form.place)
    const dispatch = useDispatch()

    const [place, setPlace] = useState(reduxPlace)

    useEffect(() => {
        dispatch(updateStep(0))
    }, [])

    const handleOnClick = (value) => {
        setPlace(value)
    }

    const handleData = () => {
        dispatch(updatePlace(place))
    }

    const salles = ['Paris', 'Havana', 'Bali', 'Florence', 'New York', 'Londres']
    const sallesDesktopImages = [Cottage, TheClub, Zen, Peps, SalleNewyork, SalleLondre]
    const sallesMobileImages = [CottageMobile, TheClubMobile, ZenMobile, PepsMobile, SalleNewyork, SalleLondre]

    return (
        <div>
            <Header/>
            <StepCard stepNumber={0} handleData={handleData} nextPath={'/schedule'} />
            <PlaceSetterCard handleOnClick={handleOnClick} selectedPlace={place} salles={salles}/>
            
            {
                salles.map((salle, i) => <PlaceImageCard name={salle}
                 selectedPlace={place}
                  handleOnClick={handleOnClick} 
                desktopImage={sallesDesktopImages[i]}
                 mobileImage={sallesMobileImages[i]} />)
            }
        </div>
    )
}

export default Place