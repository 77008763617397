import { Header } from 'antd/lib/layout/layout'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import AfterworkTeambuilding from '../Components/AfterworkTeambuilding'
import Catering from '../Components/Catering'
import ContactForm from '../Components/ContactForm'
import LastStep from '../Components/LastStep'
import Footer from '../Components/Layout/Footer'
import Navbar from '../Components/Layout/Navbar'
import Place from '../Components/Place'
import Room from '../Components/Room'
import WellnessFacilitation from '../Components/WellnessFacilitation'

const FormRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Place/>} />
                <Route path='/schedule' element={<Room/>} />
                <Route path='/catering' element={<Catering/>} />
                <Route path='/wellness-facilitgator' element={<WellnessFacilitation/>} />
                <Route path='/afterwork-teambuilding' element={<AfterworkTeambuilding/>} />
                <Route path='/reserve' element={<ContactForm/>} />
                <Route path='/merci' element={<LastStep/>} />
            </Routes>
        </BrowserRouter>
    )
}

export default FormRoutes