import { TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import StepCard from "../StepCard"
import Header from '../Header'

import Config from '../../Config'


import './index.css'
import { updateStep } from "../../Features/formSlice"
import { Link } from "react-router-dom"


const ContactForm = () => {

    const dispatch = useDispatch()

    const reduxPlace = useSelector((state) => state.form.place)
    const reduxDays = useSelector((state) => state.form.days)
    const reduxLunch = useSelector((state) => state.form.lunch)
    const reduxWellness = useSelector((state) => state.form.wellness)
    const reduxFacilitation = useSelector((state) => state.form.facilitation)
    const reduxAfterwork = useSelector((state) => state.form.afterwork)
    const reduxTeambuilding = useSelector((state) => state.form.teambuilding)
    const reduxBrainstorming = useSelector((state) => state.form.brainstorming)

    const [firstName, setFistName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [company, setCompany] = useState('')

    useEffect(() => {
        dispatch(updateStep(5))
    }, [])

    const handleFirstNameOnChange = (e) => {
        setFistName(e.target.value)
    }

    const handleLastNameOnChange = (e) => {
        setLastName(e.target.value)
    }

    const handleEmailOnChange = (e) => {
        setEmail(e.target.value)
    }

    const handlePhoneOnChange = (e) => {
        setPhone(e.target.value)
    }

    const handleCompanyOnChange = (e) => {
        setCompany(e.target.value)
    }

    const sumbitData = async () => {
        const response = await fetch(`${Config.api_url}/api/v1/reservations/create`, {
            method: "POST",
            mode: "cors",
            body: JSON.stringify({
                place: reduxPlace,
                days: reduxDays,
                lunch: reduxLunch,
                wellness: reduxWellness,
                facilitation: reduxFacilitation,
                afterwork: reduxAfterwork,
                teambuilding: reduxTeambuilding,
                brainstorming: reduxBrainstorming,
                name: `${firstName} ${lastName}`,
                email,
                phone,
                company
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })

        return response.json()
    }


    return (
        <div>
            <Header/>
            {/* <StepCard stepNumber={5} handleData={sumbitData} nextPath={''} previousPath={'/afterwork-teambuilding'}/> */}
            <div className="step-card" style={{justifyContent: 'center'}}>
                <h1 className="step-card-text">Confirmer votre demande de réservation</h1>
            </div>
            <div className='contact-form-container'>
                <div className="contact-form-text">
                    <h2>Merci de renseigner le formulaire ci-dessous</h2>
                </div>
                <div className='contact-form-inputs'>
                    <TextField id="last_name" label="Nom" variant="standard" onChange={(e) => handleLastNameOnChange(e)}/>
                    <TextField id="first_name" label="Prénom" variant="standard" onChange={(e) => handleFirstNameOnChange(e)}/>
                    <TextField id="company" label="Société" variant="standard" onChange={(e) => handleCompanyOnChange(e)}/>
                    <TextField id="email" label="Email" variant="standard" inputProps={{type: 'email'}} onChange={(e) => handleEmailOnChange(e)}/>
                    <TextField id="phone" label="Téléphone" variant="standard" onChange={(e) => handlePhoneOnChange(e)}/>  
                </div>
                <div className="contact-form-buttons">
                    <Link className="contact-form-button" to={'/merci'} onClick={sumbitData}>Envoyer la demande de réservation</Link>
                </div>
            </div>
        </div>
    )
}

export default ContactForm