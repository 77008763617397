import './index.css'

const RoomImageCard = ({image, title, svg}) => {
    return (
        <div className='room-image-card' style={{backgroundImage: `url("${image}")`}}>

            {/* <div className='room-image-svg'>
                {svg}
                <div className='room-image-svg-title'>{title}</div>
            </div> */}
        </div>
    )
}

export default RoomImageCard